import { isEmpty } from 'lodash'
import { type FC, type ReactNode } from 'react'
import { RelayEnvironmentProvider } from 'react-relay'
import { Environment, Network, RecordSource, type RequestParameters, Store, type Variables } from 'relay-runtime'
import { type RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes'

let environment: Environment | null = null
let lastUserId: string | null = null

export const getEnvironment = (
  token: string | null | undefined,
  relayData: RecordMap | undefined,
  userId: string | null
) => {
  const isServer = typeof window === typeof undefined

  if (isServer || !environment || userId !== lastUserId) {
    environment = new Environment({
      configName: userId || 'unauthenticated',
      network: Network.create(async (operation: RequestParameters, variables: Variables) => {
        const headers: HeadersInit = {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }

        if (token) {
          headers.Authorization = `Bearer ${token}`
        }

        const res = await fetch(`${process.env.NEXT_PUBLIC_APP_URL}/api/graphql`, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            query: operation.text,
            variables
          })
        })

        return res.json()
      }),
      store: new Store(new RecordSource()),
      isServer
    })

    if (!isEmpty(relayData)) {
      // populate the store with any already existing relay data
      environment.getStore().publish(new RecordSource(relayData))
    }

    lastUserId = userId
  }

  return environment
}

export interface RelayProviderProps {
  children: ReactNode
  relayData: RecordMap | undefined
  token: string | null | undefined
  userId: string | null
}

export const RelayProvider: FC<RelayProviderProps> = ({ children, relayData, token, userId }) => (
  <RelayEnvironmentProvider environment={getEnvironment(token, relayData, userId)}>{children}</RelayEnvironmentProvider>
)
