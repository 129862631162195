import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production', 'staging']
})

export default Bugsnag
export const BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary()
