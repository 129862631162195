import { createContext, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const PreviousUrlContext = createContext<string | null>(null)

export const PreviousUrlProvider = ({ children }) => {
  const [prevUrl, setPrevUrl] = useState<string | null>(() => {
    return typeof window !== 'undefined' ? localStorage.getItem('previousUrl') : null
  })

  const url = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!prevUrl || url.startsWith('/platform/dashboard')) {
        setPrevUrl(url)
        localStorage.setItem('previousUrl', url)
      } else if (url.startsWith('/platform')) {
        setPrevUrl(null)
        localStorage.removeItem('previousUrl')
      }
    }

    url.events.on('routeChangeStart', handleRouteChange)
    return () => {
      url.events.off('routeChangeStart', handleRouteChange)
    }
  }, [url, prevUrl])

  return <PreviousUrlContext.Provider value={prevUrl}>{children}</PreviousUrlContext.Provider>
}

export const usePreviousUrl = () => useContext(PreviousUrlContext)
