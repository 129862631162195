import { createStore } from 'zustand/vanilla'
import { persist, createJSONStorage } from 'zustand/middleware'

type OrganizationType = {
  id: string
  name: string
  slug: string
}

export interface OrganizationState {
  organization: OrganizationType | null
}

export interface OrganizationActions {
  updateOrganization: (organization: OrganizationType) => void
}

export type OrganizationStore = OrganizationState & OrganizationActions

export const defaultInitOrganizationState: OrganizationState = {
  organization: {
    id: '',
    name: '',
    slug: ''
  }
}

export const createOrganizationStore = (initState: OrganizationState = defaultInitOrganizationState) => {
  return createStore<OrganizationStore>()(
    persist(
      (set) => ({
        ...initState,
        updateOrganization: (updatedFields) =>
          set((state) => ({
            organization: state.organization ? { ...state.organization, ...updatedFields } : null
          }))
      }),
      {
        name: 'organization-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
}
