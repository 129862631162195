'use client'

import { useStore } from 'zustand'

import { type ReactNode, createContext, useRef, useContext } from 'react'
import { type OrganizationStore, createOrganizationStore } from '../stores/organizationStore'

export type OrganizationStoreApi = ReturnType<typeof createOrganizationStore>

export const OrganizationContext = createContext<OrganizationStoreApi | undefined>(undefined)

export interface OrganizationStoreProps {
  children: ReactNode
}

export const OrganizationStoreProvider = ({ children }: OrganizationStoreProps) => {
  const organizationStoreRef = useRef<OrganizationStoreApi>()

  if (!organizationStoreRef.current) {
    organizationStoreRef.current = createOrganizationStore()
  }

  return <OrganizationContext.Provider value={organizationStoreRef.current}>{children}</OrganizationContext.Provider>
}

export const useOrganizationStore = <T,>(selector: (store: OrganizationStore) => T): T => {
  const contextValue = useContext(OrganizationContext)

  if (!contextValue) {
    throw new Error(`useOrganizationStore must be used within OrganizationStoreProvider`)
  }

  return useStore(contextValue, selector)
}
