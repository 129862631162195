import '@app/lib/setupDayJs'

import { RouterTransition } from '@app/components/layout/RouterTransition'
import { MantineProvider } from '@app/providers/MantineProvider'
import { RelayProvider } from '@app/providers/RelayProvider'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import { type Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { type RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes'
import { OrganizationStoreProvider } from '@app/providers/OrganizationProviders'
import { PreviousUrlProvider } from '@app/lib/usePreviousUrl'
import { BugsnagErrorBoundary } from '@app/utils/bugsnag'

interface PageProps {
  relayData: RecordMap | undefined
  session: Session | null
  token: string | null | undefined
}

const refetchIntervalSeconds = 300

const App = ({ Component, pageProps: { relayData, session, token, ...pageProps } }: AppProps<PageProps>) => (
  <BugsnagErrorBoundary>
    <SessionProvider
      refetchInterval={refetchIntervalSeconds}
      refetchOnWindowFocus
      session={session}
    >
      <RelayProvider
        relayData={relayData}
        token={token}
        userId={session?.user?.id}
      >
        <MantineProvider>
          <PreviousUrlProvider>
            <OrganizationStoreProvider>
              <Head>
                <title>SWAP PM</title>
                <meta
                  name='viewport'
                  content='minimum-scale=1, initial-scale=1, width=device-width'
                />
                <link
                  rel='apple-touch-icon'
                  sizes='180x180'
                  href='/apple-touch-icon.png'
                />
                <link
                  rel='icon'
                  type='image/png'
                  sizes='32x32'
                  href='/favicon-32x32.png'
                />
                <link
                  rel='icon'
                  type='image/png'
                  sizes='16x16'
                  href='/favicon-16x16.png'
                />
                <link
                  rel='manifest'
                  href='/site.webmanifest'
                />
                <link
                  rel='mask-icon'
                  href='/safari-pinned-tab.svg'
                  color='#cccccc'
                />
                <meta
                  name='apple-mobile-web-app-title'
                  content='SWAP PM'
                />
                <meta
                  name='application-name'
                  content='SWAP PM'
                />
                <meta
                  name='msapplication-TileColor'
                  content='#ffffff'
                />
                <meta
                  name='theme-color'
                  content='#ffffff'
                />
              </Head>
              <RouterTransition />
              <Component {...pageProps} />
            </OrganizationStoreProvider>
          </PreviousUrlProvider>
        </MantineProvider>
      </RelayProvider>
    </SessionProvider>
  </BugsnagErrorBoundary>
)

// noinspection JSUnusedGlobalSymbols
export default App
